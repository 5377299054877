import axios from 'axios';

const WHITELIST_FUNCTION_URL =
  'https://southamerica-west1-rugged-layout-424001-h4.cloudfunctions.net/checkWhitelist';
const CLIENT_ID_FUNCTION_URL =
  'https://southamerica-west1-rugged-layout-424001-h4.cloudfunctions.net/getClientId/get-client-id';

export const checkWhitelist = async (email: string) => {
  try {
    const response = await axios.get(WHITELIST_FUNCTION_URL, {
      params: { email },
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data);
    } else {
      throw new Error(error.message);
    }
  }
};

export const getClientId = async (email: string) => {
  try {
    const response = await axios.get(CLIENT_ID_FUNCTION_URL, {
      params: { email },
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data);
    } else {
      throw new Error(error.message);
    }
  }
};
