import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import logo from '../../assets/banner.png';

const looker =
  'https://lookerstudio.google.com/embed/reporting/abd93ba8-3a36-4f50-94e8-9380689ff4ce';

const HomeBebidas: React.FC = () => {
  const auth = getAuth();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.href = '/login';
      localStorage.removeItem('client_id');
    } catch (error) {
      console.error('Error logging out', error);
    }
  };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <img
            src={logo}
            alt="Logo"
            style={{ height: '65px', marginRight: '16px' }}
          />
          <Typography variant="h6" style={{ flexGrow: 1 }}></Typography>
          {/* <Button color="inherit" onClick={handleOpen}>
            Descargar Excel
          </Button> */}
          <Button color="inherit" onClick={handleLogout}>
            Salir
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <iframe
          title="Looker Studio Report"
          width="100%"
          height="100%"
          src={looker}
          style={{ flex: 1, border: 0 }}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default HomeBebidas;
