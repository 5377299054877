import React, { useState } from 'react';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

export default function RecoverPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [message, setMessage] = useState('');
  const [searchParams] = useSearchParams();

  const auth = getAuth();
  const oobCode = searchParams.get('oobCode');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPasswordError('');
    setMessage('');

    if (password !== confirmPassword) {
      setPasswordError('Las contraseñas no coinciden.');
      return;
    }

    if (password.length < 6) {
      setPasswordError('La contraseña debe tener al menos 6 caracteres.');
      return;
    }

    try {
      if (oobCode) {
        await confirmPasswordReset(auth, oobCode, password);
        setMessage('Contraseña actualizada correctamente.');
      } else {
        throw new Error('No se ha encontrado el código de restablecimiento.');
      }
    } catch (error) {
      setMessage(
        'Error al actualizar la contraseña. Por favor, inténtalo de nuevo.'
      );
      console.error('Error updating password', error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Recuperar Contraseña
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Nueva Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirmar Contraseña"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Aceptar
          </Button>
          {message && (
            <Typography variant="body2" color="primary" align="center">
              {message}
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
}
