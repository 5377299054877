import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { getAuth } from 'firebase/auth';
import { checkWhitelist, getClientId } from '../../api/api';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

export default function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const auth = getAuth();

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEmailError('');
    setPasswordError('');
    setGeneralError('');
    setSuccessMessage('');

    if (password !== confirmPassword) {
      setPasswordError('Las contraseñas no coinciden.');
      return;
    }

    if (password.length < 6) {
      setPasswordError('La contraseña debe tener al menos 6 caracteres.');
      return;
    }

    try {
      // Verificar si el correo electrónico está en la whitelist
      const whitelistUser = await checkWhitelist(email);
      if (!whitelistUser.client_id) {
        setEmailError(
          'Este correo electrónico no está autorizado para registrarse.'
        );
        return;
      }

      // Registrar al usuario en Firebase Authentication
      const clientIdResponse = await getClientId(email);
      localStorage.setItem('client_id', clientIdResponse.client_id);
      console.log(localStorage.getItem('client_id'));
      logEvent(analytics, 'signUp', { method: 'password' });
      await createUserWithEmailAndPassword(auth, email, password);

      setSuccessMessage('Usuario registrado con éxito.');
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        setGeneralError('El correo electrónico ya está en uso.');
        return;
      }

      console.error('Error al registrar el usuario:', error);
      setGeneralError('Error al registrar el usuario.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Inscribirse
        </Typography>
        <Box component="form" onSubmit={handleSignUp} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirmar Contraseña"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Registrarse
          </Button>
          {generalError && (
            <Typography variant="body2" color="error" align="center">
              {generalError}
            </Typography>
          )}
          {successMessage && (
            <Typography variant="body2" color="text.primary" align="center">
              {successMessage}
            </Typography>
          )}
          <Grid container>
            <Grid item>
              <Link href="/login" variant="body2">
                {'¿Ya tienes una cuenta? Iniciar Sesión'}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
