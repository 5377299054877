// LoadingSpinner.tsx
import React from 'react';
import './LoadingSpinner.css';
import icono from '../../assets/Icono.png';

type LoadingSpinnerProps = {
  fullHeight?: boolean;
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (LoadingSpinnerProps) => {
  const height = LoadingSpinnerProps.fullHeight ? '100vh' : 'auto';
  return (
    <div className="spinner-container" style={{ height }}>
      <div className="spinner">
        <img src={icono} alt="Loading" className="spinner-logo" />
      </div>
    </div>
  );
};

export default LoadingSpinner;
