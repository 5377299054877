import { useEffect } from 'react';
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import RecoverPassword from '../SetPassword/RecoverPassword';

const FinishSignUp = () => {
  const auth = getAuth();
  const email = window.localStorage.getItem('emailForSignIn');

  useEffect(() => {
    if (!auth || !email) return;
    if (isSignInWithEmailLink(auth, window.location.href)) {
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
          console.log('Usuario autenticado:', result.user);
        })
        .catch((error) => {
          console.error('Error al completar el inicio de sesión:', error);
        });
    }
  }, [auth, email]);

  return <RecoverPassword />;
};

export default FinishSignUp;
