import React, { useEffect, useState } from 'react';
import HomeAconcagua from './HomeAconcagua';
import HomeBebidas from './HomeBebidas';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase';
import { getAuth } from 'firebase/auth';

const Home: React.FC = () => {
  const [clientId, setClientId] = useState<string | null>(null);
  const auth = getAuth();

  useEffect(() => {
    const storedClientId = localStorage.getItem('client_id');
    setClientId(storedClientId);
    const email = auth.currentUser?.email;

    if (storedClientId) {
      logEvent(analytics, 'home_page_visited', {
        user_email: email,
      });
    }
  }, [auth]);

  if (!clientId) {
    return <LoadingSpinner fullHeight={true} />;
  }

  switch (clientId) {
    case 'aconcagua':
      return <HomeAconcagua />;
    case 'bebidas':
      return <HomeBebidas />;
    default:
      return <div>Cliente desconocido</div>;
  }
};

export default Home;
