import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import logo from '../../assets/banner.png';

const mermeladasLooker =
  'https://lookerstudio.google.com/embed/reporting/90810b2a-4310-4c13-abec-056edb11d191';
const frutasEnConservaLooker =
  'https://lookerstudio.google.com/embed/reporting/893cc501-c321-4cdd-859b-2099fb827021';

const HomeAconcagua: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const initialButton = queryParams.get('looker') || 'mermeladas';
  const initialLooker =
    initialButton === 'frutas_en_conserva'
      ? frutasEnConservaLooker
      : mermeladasLooker;

  const [lookerUrl, setLookerUrl] = useState<string>(initialLooker);
  const [activeButton, setActiveButton] = useState<string>(initialButton);

  const auth = getAuth();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.href = '/login';
      localStorage.removeItem('client_id');
    } catch (error) {
      console.error('Error logging out', error);
    }
  };

  const handleButtonClick = (url: string, button: string) => {
    setLookerUrl(url);
    setActiveButton(button);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('looker', button);
    window.history.pushState(null, '', `?${queryParams.toString()}`);
  };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <img
            src={logo}
            alt="Logo"
            style={{ height: '65px', marginRight: '16px' }}
          />
          <Typography variant="h6" style={{ flexGrow: 1 }}></Typography>
          <Button
            color="inherit"
            variant={activeButton === 'mermeladas' ? 'outlined' : 'text'}
            onClick={() => handleButtonClick(mermeladasLooker, 'mermeladas')}
          >
            Mermeladas
          </Button>
          <Button
            color="inherit"
            variant={
              activeButton === 'frutas_en_conserva' ? 'outlined' : 'text'
            }
            onClick={() =>
              handleButtonClick(frutasEnConservaLooker, 'frutas_en_conserva')
            }
          >
            Frutas en Conserva
          </Button>
          <Button color="inherit" onClick={handleLogout}>
            Salir
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <iframe
          title="Looker Studio Report"
          width="100%"
          height="100%"
          src={lookerUrl}
          style={{ flex: 1, border: 0 }}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default HomeAconcagua;
