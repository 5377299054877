import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
// import GoogleLogo from '../../assets/google.svg'; // Importa el archivo SVG
import { getClientId } from '../../api/api';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const auth = getAuth();

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const clientIdResponse = await getClientId(email);
      localStorage.setItem('client_id', clientIdResponse.client_id);
      console.log(localStorage.getItem('client_id'));
      logEvent(analytics, 'login', { method: 'password' });
      window.location.href = '/home';
    } catch (error) {
      console.error('Error logging in', error);
      setErrorMessage(
        'Credenciales incorrectas. Por favor, inténtalo de nuevo.'
      );
    }
  };

  // const handleGoogleSignIn = async () => {
  //   const provider = new GoogleAuthProvider();
  //   try {
  //     const result = await signInWithPopup(auth, provider);
  //     const email = result.user.email!;
  //     const clientIdResponse = await getClientId(email);
  //     localStorage.setItem('client_id', clientIdResponse.client_id);
  //     window.location.href = '/home';
  //   } catch (error: any) {
  //     await signOut(auth);
  //     console.error('Error with Google sign-in', error);
  //     setErrorMessage(
  //       error.message || 'Error con el inicio de sesión de Google'
  //     );
  //   }
  // };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Iniciar Sesión
        </Typography>
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errorMessage}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errorMessage}
            helperText={errorMessage}
          />
          <Button
            type="submit"
            color="primary"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Iniciar Sesión
          </Button>
          {/* <Button
            fullWidth
            variant="outlined"
            sx={{
              mt: 1,
              mb: 2,
              backgroundColor: 'white',
              color: 'rgba(0, 0, 0, 0.54)',
              textTransform: 'none',
              boxShadow: 'none',
              border: '1px solid #ccc',
              padding: '8px 16px',
            }}
            onClick={handleGoogleSignIn}
            startIcon={
              <img
                src={GoogleLogo}
                alt="Google logo"
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            }
          >
            Iniciar Sesión con Google
          </Button> */}
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                ¿Olvidaste tu contraseña?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {'¿No tienes una cuenta? Inscribirse'}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
