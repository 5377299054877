import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import Home from './components/Home/Home';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import FinishSignUp from './components/FinishSignUp/FinishSignUp';
import RecoverPassword from './components/SetPassword/RecoverPassword';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import Logout from './components/Logout/Logout';

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <LoadingSpinner fullHeight={true} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={user ? <Navigate to="/home" /> : <Login />}
          />
          <Route
            path="/signup"
            element={user ? <Navigate to="/home" /> : <SignUp />}
          />
          <Route path="/finishSignUp" element={<FinishSignUp />} />
          <Route path="/setPassword" element={<RecoverPassword />} />
          <Route
            path="/forgot-password"
            element={user ? <Navigate to="/home" /> : <ForgotPassword />}
          />
          <Route
            path="/home"
            element={user ? <Home /> : <Navigate to="/login" />}
          />
          <Route
            path="/"
            element={<Navigate to={user ? '/home' : '/login'} />}
          />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
