import React, { useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';

const Logout: React.FC = () => {
  const auth = getAuth();

  useEffect(() => {
    const performLogout = async () => {
      try {
        await signOut(auth);
        localStorage.removeItem('client_id');
        window.location.href = '/login';
      } catch (error) {
        console.error('Error logging out', error);
      }
    };

    performLogout();
  }, [auth]);

  return <div>Logging out...</div>;
};

export default Logout;
